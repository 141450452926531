import React, { useState, useEffect } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';

function CadastroUsuarioPanel() {
    const [usuarios, setUsuarios] = useState([]);
    const [selectedUser, setSelectedUser] = useState('Novo Usuário');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [roles, setRoles] = useState([]);
    const [availableRoles, setAvailableRoles] = useState([]);
    const [message, setMessage] = useState({ text: '', type: '' });
    const [loading, setLoading] = useState(false);
    const [empresaId, setEmpresaId] = useState(null);

    const navigate = useNavigate(); // Definir o navigate para redirecionar

    // Buscar dados iniciais (usuários, roles e empresa do usuário logado)
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);

                // Obter empresaId do usuário logado
                const empresaIdFromStorage = localStorage.getItem('empresaId');
                if (empresaIdFromStorage) {
                    setEmpresaId(empresaIdFromStorage);
                } else {
                    console.error('Empresa não encontrada no localStorage.');
                    setMessage({ text: 'Erro ao identificar empresa do usuário.', type: 'danger' });
                    return;
                }

                // Obter lista de roles
                const roles = await api.getRoles();
                if (roles && Array.isArray(roles)) {
                    setAvailableRoles(roles);
                } else {
                    console.error('Formato inesperado ao carregar roles:', roles);
                    setMessage({ text: 'Erro ao carregar roles: Formato inesperado.', type: 'danger' });
                }

                // Obter lista de usuários
                const users = await api.getClients();
                if (users && users.clients) {
                    setUsuarios(users.clients.sort((a, b) => a.name.localeCompare(b.name)));
                } else {
                    console.error('Formato inesperado ao carregar usuários:', users);
                    setMessage({ text: 'Erro ao carregar usuários: Formato inesperado.', type: 'danger' });
                }
            } catch (error) {
                console.error('Erro ao carregar dados:', error);
                if (error.response?.status === 401) {
                    console.log('Token expirado ou inválido.');
                    setMessage({ text: 'Sua sessão expirou. Faça login novamente.', type: 'danger' });
                    // setTimeout(() => navigate('/login'), 2000);
                } else {
                    setMessage({ text: 'Erro ao carregar dados. Tente novamente.', type: 'danger' });
                }
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [navigate]);

    // Buscar detalhes do usuário selecionado
    useEffect(() => {
        const fetchUserDetails = async () => {
            if (selectedUser !== 'Novo Usuário') {
                try {
                    const response = await api.getUserDetails(selectedUser);
                    if (response) {
                        const { name = '', email = '', roles = [] } = response;
                        setUsername(name);
                        setEmail(email);
                        setRoles(roles.map((role) => role.id || role));
                    } else {
                        console.error('Dados do usuário não encontrados na resposta da API.');
                        setMessage({ text: 'Erro: Dados do usuário não encontrados.', type: 'danger' });
                    }
                    setPassword('');
                } catch (error) {
                    console.error('Erro ao buscar detalhes do usuário:', error);
                    setMessage({
                        text: `Erro ao buscar detalhes do usuário: ${error.message || 'Erro desconhecido'}`,
                        type: 'danger',
                    });
                }
            } else {
                setUsername('');
                setEmail('');
                setPassword('');
                setRoles([]);
            }
        };
        fetchUserDetails();
    }, [selectedUser]);

    const validateFields = () => {
        if (!username || !email || (!password && selectedUser === 'Novo Usuário') || roles.length === 0) {
            setMessage({ text: 'Todos os campos são obrigatórios.', type: 'danger' });
            return false;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setMessage({ text: 'Por favor, insira um e-mail válido.', type: 'danger' });
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateFields()) return;

        try {
            setLoading(true);

            if (selectedUser === 'Novo Usuário') {
                const response = await api.cadastrarUsuario({
                    username,
                    email,
                    password,
                    roles,
                    empresa_id: empresaId, // Passa o empresaId do usuário logado
                });
                setMessage({ text: response.message, type: 'success' });
            } else {
                const response = await api.editarUsuario({
                    userId: selectedUser,
                    username,
                    email,
                    roles,
                });
                setMessage({ text: response.message, type: 'success' });
            }
        } catch (error) {
            const errorMsg = error.response ? error.response.data.message : 'Erro ao realizar operação.';
            setMessage({ text: errorMsg, type: 'danger' });
        } finally {
            setLoading(false);
        }

        setTimeout(() => setMessage({ text: '', type: '' }), 15000);
    };

    return (
        <Card className="shadow-sm mb-4">
            <Card.Header as="h5">
                Cadastro de Usuário
            </Card.Header>
            <Card.Body>
                {message.text && <Alert variant={message.type}>{message.text}</Alert>}
                {loading && <Alert variant="info">Carregando...</Alert>}

                <Form.Group controlId="userSelect" className="mb-3">
                    <Form.Label>Selecione o Usuário</Form.Label>
                    <Form.Control as="select" value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
                        <option value="Novo Usuário">Novo Usuário</option>
                        {usuarios.map((user) => (
                            <option key={user.id} value={user.id}>
                                {user.name}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>

                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="username" className="mb-3">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nome do usuário"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="email" className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Email do usuário"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </Form.Group>

                    {selectedUser === 'Novo Usuário' && (
                        <Form.Group controlId="password" className="mb-3">
                            <Form.Label>Senha</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Senha"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </Form.Group>
                    )}

                    <Form.Group controlId="roles" className="mb-3">
                        <Form.Label>Roles</Form.Label>
                        <Form.Control
                            as="select"
                            multiple
                            value={roles}
                            onChange={(e) =>
                                setRoles([...e.target.selectedOptions].map((option) => Number(option.value)))
                            }
                            required
                        >
                            {availableRoles.map((role) => (
                                <option key={role.id} value={role.id}>
                                    {role.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>

                    <Button variant="primary" type="submit" className="w-100" disabled={loading}>
                        {selectedUser === 'Novo Usuário' ? 'Cadastrar' : 'Editar'}
                    </Button>
                </Form>
            </Card.Body>
        </Card>
    );
}

export default CadastroUsuarioPanel;
