import React, { useState, useEffect } from 'react';
import { Form, Button, Card, ListGroup, Col, Row } from 'react-bootstrap';
import api from '../../services/api';

function PermissaoUsuarioPanel() {
    const [usuarios, setUsuarios] = useState([]);  // Lista de usuários
    const [indicadores, setIndicadores] = useState([]);  // Lista de todos os indicadores
    const [selectedUser, setSelectedUser] = useState('');  // Usuário selecionado
    const [selectedIndicators, setSelectedIndicators] = useState([]);  // Indicadores habilitados

    // Buscar usuários na API ao carregar o componente
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await api.getClients();  // Função que traz os usuários
                const sortedUsers = response.clients.sort((a, b) => a.name.localeCompare(b.name));  // Ordena os usuários pelo nome
                setUsuarios(sortedUsers);  // Define a lista de usuários ordenada                
            } catch (error) {
                console.error('Erro ao buscar usuários:', error);
            }
        };
        fetchUsers();
    }, []);

    // Buscar todos os indicadores e os habilitados para o usuário selecionado
    useEffect(() => {
        const fetchIndicators = async () => {
            try {
                // Obtém todos os indicadores disponíveis
                const allIndicatorsResponse = await api.getAllIndicators();
                const allIndicators = allIndicatorsResponse.indicators;
                setIndicadores(allIndicators);  // Define todos os indicadores

                // Obtém os indicadores habilitados na tabela Acesso_Usuario_Indicador
                if (selectedUser) {
                    const enabledIndicatorsResponse = await api.getEnabledIndicatorsByUser(selectedUser);
                    const enabledIndicators = enabledIndicatorsResponse.map(ind => ind.indicador_id);  // Pega os ids dos indicadores habilitados
                    setSelectedIndicators(enabledIndicators);  // Define os indicadores habilitados
                } else {
                    setSelectedIndicators([]);  // Caso nenhum usuário seja selecionado, limpa os selecionados
                }
            } catch (error) {
                console.error('Erro ao buscar indicadores:', error);
            }
        };

        if (selectedUser) {
            fetchIndicators();
        }
    }, [selectedUser]);

    // Função para atualizar os indicadores selecionados (checkbox)
    const handleCheckboxChange = (indicatorId) => {
        setSelectedIndicators((prevSelectedIndicators) =>
            prevSelectedIndicators.includes(indicatorId)
                ? prevSelectedIndicators.filter(id => id !== indicatorId)  // Remove se desmarcado
                : [...prevSelectedIndicators, indicatorId]  // Adiciona se marcado
        );
    };

    // Função para salvar as permissões de acesso ao clicar no botão "Salvar"
    const handleSavePermissions = async () => {
        if (!selectedUser || selectedIndicators.length === 0) {
            alert('Selecione um usuário e pelo menos um indicador.');
            return;
        }

        try {
            // Envia os dados para salvar os novos indicadores selecionados e remover os desmarcados
            const data = {
                user_id: selectedUser,
                enabledIndicators: selectedIndicators,
                allIndicators: indicadores.map(ind => ind.id)  // Lista de todos os indicadores
            };

            await api.updatePermissions(data);

            alert('Permissões atualizadas com sucesso!');
        } catch (error) {
            console.error('Erro ao atualizar permissões:', error);
            alert('Erro ao atualizar permissões.');
        }
    };

    return (
        <Card className="shadow-sm mb-4">
            <Card.Header as="h5">Permissão de Usuário</Card.Header>
            <Card.Body>
                {/* Dropdown para selecionar o usuário */}
                <Form.Group controlId="userSelect" className="mb-3">
                    <Form.Label>Selecione o Usuário</Form.Label>
                    <Form.Control as="select" value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
                        <option value="">Selecione um usuário</option>
                        {usuarios.map(user => (
                            <option key={user.id} value={user.id}>{user.name}</option>
                        ))}
                    </Form.Control>
                </Form.Group>

                {/* Lista de indicadores com checkbox */}
                {indicadores.length > 0 ? (
                    <ListGroup>
                        {indicadores.map(indicator => (
                            <ListGroup.Item key={indicator.id}>
                                <Form.Check
                                    type="checkbox"
                                    label={indicator.descricao}
                                    checked={selectedIndicators.includes(indicator.id)}
                                    onChange={() => handleCheckboxChange(indicator.id)}
                                />
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                ) : (
                    <p>Nenhum indicador disponível para este usuário.</p>
                )}

                {/* Botão para salvar as permissões */}
                <Row className="mt-4">
                    <Col>
                        <Button variant="primary" onClick={handleSavePermissions} className="w-100">Salvar</Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
}

export default PermissaoUsuarioPanel;
