import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Card } from 'react-bootstrap';
import api from '../../services/api';

function MunicipiosInativosPanel() {
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [supervisor, setSupervisor] = useState('');
    const [supervisores, setSupervisores] = useState([]);
    const [municipiosData, setMunicipiosData] = useState({
        graph_url: null,
        total_municipios: 0,
        municipios_com_vendas: 0,
        municipios_inativos: 0
    });

    useEffect(() => {
        api.getSupervisores().then(data => {
            const sortedSupervisores = data.supervisores
                .filter(s => s) // Remove valores nulos ou vazios
                .sort((a, b) => a.localeCompare(b));
            setSupervisores(sortedSupervisores);
        });
    }, []);

    const fetchMunicipiosData = async () => {
        if (dataInicio && dataFim) {
            try {
                const response = await api.get('/municipios_inativos', { params: { dataInicio, dataFim, supervisor } });
                setMunicipiosData(response.data);
            } catch (error) {
                console.error('Erro ao buscar dados dos municípios inativos:', error);
            }
        }
    };

    useEffect(() => {
        fetchMunicipiosData();
    }, [dataInicio, dataFim, supervisor]);

    return (
        <Card className="shadow-sm mb-4">
            <Card.Header as="h5">Municípios Inativos</Card.Header>
            <Card.Body>
                <Row className="mb-3">
                    <Col md={4}>
                        <Form.Group controlId="formDataInicio">
                            <Form.Label>Data Início</Form.Label>
                            <Form.Control
                                type="date"
                                value={dataInicio}
                                onChange={e => setDataInicio(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="formDataFim">
                            <Form.Label>Data Fim</Form.Label>
                            <Form.Control
                                type="date"
                                value={dataFim}
                                onChange={e => setDataFim(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="formSupervisor">
                            <Form.Label>Supervisor</Form.Label>
                            <Form.Control
                                as="select"
                                value={supervisor}
                                onChange={e => setSupervisor(e.target.value)}
                            >
                                <option value="">Selecione o Supervisor</option>
                                {supervisores.map(s => (
                                    <option key={s} value={s}>{s}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                {municipiosData.graph_url ? (
                    <div className="municipios-inativos-chart mt-4 text-center">
                        <h5>Total de Municípios onde já houve vendas: {municipiosData.total_municipios}</h5>
                        <h6>Municípios com Vendas no Período: {municipiosData.municipios_com_vendas}</h6>
                        <h6>Municípios Inativos no Período: {municipiosData.municipios_inativos}</h6>
                        <img
                            src={`data:image/png;base64,${municipiosData.graph_url}`}
                            alt="Gráfico de Municípios"
                            className="img-fluid mx-auto d-block"
                        />
                    </div>
                ) : (
                    <p>Selecione uma data de início, data de fim e um supervisor para ver os dados.</p>
                )}
            </Card.Body>
        </Card>
    );
}

export default MunicipiosInativosPanel;
