import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import api from '../../services/api';

function ReversaoTratativas() {
    return (
        <Card className="shadow-sm mb-4">
            <Card.Header as="h5">Reversão de Tratativas</Card.Header>
            <Card.Body>
                <Row className="mb-3">

                </Row>

            </Card.Body>
        </Card>
    );
}

export default ReversaoTratativas;
