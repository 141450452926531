import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import api from '../../services/api';

function VendaProdutoSupervisor() {
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [supervisor, setSupervisor] = useState('');
    const [representante, setRepresentante] = useState('');
    const [supervisores, setSupervisores] = useState([]);
    const [representantes, setRepresentantes] = useState([]);
    const [graphUrl, setGraphUrl] = useState(null);
    const [totalKg, setTotalKg] = useState(0);
    const [filtro, setFiltro] = useState('supervisor_produto');

    useEffect(() => {
        api.getSupervisores().then(data => {
            const sortedSupervisores = data.supervisores
                .filter(s => s !== null)
                .sort((a, b) => a.localeCompare(b));
            setSupervisores(sortedSupervisores);
        });
    }, []);

    useEffect(() => {
        if (supervisor) {
            api.getRepresentantesPorSupervisor(supervisor).then(data => {
                const sortedRepresentantes = data.representantes
                    .filter(r => r !== null)
                    .sort((a, b) => a.localeCompare(b));
                setRepresentantes(sortedRepresentantes);
            }).catch(error => {
                console.error("Erro ao carregar representantes:", error);
            });
        } else {
            setRepresentantes([]);
        }
    }, [supervisor]);

    const handleSearch = () => {
        if (!dataInicio || !dataFim) {
            alert("Por favor, selecione um período válido.");
            return;
        }

        // Decide qual API chamar com base no filtro selecionado
        if (filtro === 'supervisor_produto') {
            api.getVendasPorSupervisorProduto(dataInicio, dataFim, supervisor)
                .then(response => {
                    setTotalKg(response.total_kg || response.totalKg);
                    setGraphUrl(response.graph_url || response.graphUrl);
                })
                .catch(error => {
                    console.error("Erro ao buscar vendas por supervisor e produto:", error);
                });
        } else if (filtro === 'representante_produto' && representante) {
            api.getVendasPorRepresentanteProduto(dataInicio, dataFim, supervisor, representante)
                .then(response => {
                    setTotalKg(response.total_kg || response.totalKg);
                    setGraphUrl(response.graph_url || response.graphUrl);
                })
                .catch(error => {
                    console.error("Erro ao buscar vendas por representante e produto:", error);
                });
        } else {
            console.error("Filtro inválido ou representante não selecionado para o filtro 'representante_produto'");
        }
    };

    return (
        <Card className="shadow-sm mb-4">
            <Card.Header as="h5">
                Venda por Produto e Supervisor
            </Card.Header>
            <Card.Body>
                <Row className="mb-3">
                    <Col md={4}>
                        <Form.Group controlId="formDataInicio">
                            <Form.Label>Data Início</Form.Label>
                            <Form.Control
                                type="date"
                                value={dataInicio}
                                onChange={e => setDataInicio(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="formDataFim">
                            <Form.Label>Data Fim</Form.Label>
                            <Form.Control
                                type="date"
                                value={dataFim}
                                onChange={e => setDataFim(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="formSupervisor">
                            <Form.Label>Supervisor</Form.Label>
                            <Form.Control
                                as="select"
                                value={supervisor}
                                onChange={e => setSupervisor(e.target.value)}
                            >
                                <option value="">Selecione o Supervisor</option>
                                {supervisores.map(s => (
                                    <option key={s} value={s}>{s}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                {filtro === 'representante_produto' && supervisor && (
                    <Row className="mb-3">
                        <Col md={4}>
                            <Form.Group controlId="formRepresentante">
                                <Form.Label>Representante</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={representante}
                                    onChange={e => setRepresentante(e.target.value)}
                                >
                                    <option value="">Selecione o Representante</option>
                                    {representantes.map(r => (
                                        <option key={r} value={r}>{r}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                )}

                <Row className="mb-3">
                    <Col md={6}>
                        <Form.Check
                            type="radio"
                            id="supervisor_produto"
                            name="filtro"
                            label="Por Supervisor e Produto"
                            value="supervisor_produto"
                            checked={filtro === 'supervisor_produto'}
                            onChange={e => setFiltro(e.target.value)}
                        />
                    </Col>
                    <Col md={6}>
                        <Form.Check
                            type="radio"
                            id="representante_produto"
                            name="filtro"
                            label="Por Representante e Produto"
                            value="representante_produto"
                            checked={filtro === 'representante_produto'}
                            onChange={e => setFiltro(e.target.value)}
                        />
                    </Col>
                </Row>

                <Button variant="primary" onClick={handleSearch}>
                    Buscar Vendas
                </Button>

                {graphUrl && (
                    <div className="mt-4 text-center">
                        <h5>Total de KG: {totalKg}</h5>
                        <img src={`data:image/png;base64,${graphUrl}`} alt="Gráfico de Vendas" className="img-fluid mx-auto d-block" />
                    </div>
                )}
            </Card.Body>
        </Card>
    );
}

export default VendaProdutoSupervisor;
