import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import api from "../services/api";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import Sidebar from "./Sidebar";
import AlterarSenhaPanel from "./screens/AlterarSenhaPanel";
import CadastroUsuarioPanel from "./screens/CadastroUsuarioPanel";
import PermissaoUsuarioPanel from "./screens/PermissaoUsuarioPanel";
import logo from "../assets/logov.png";

// Indicadores
import {
  ProspeccaoPanel,
  ClientesInativosPanel,
  VendedoresTicketMedioPanel,
  BalanceamentoRepres_ClienteAtivo,
  CausaReclamacoes,
  ClienteAtivo,
  Inadimplencia,
  IndiceTratativaQuantitativo,
  IndiceVendaAbaixoTabela,
  Inventario,
  MapaCalor,
  MunicipioInativo,
  PercentualMargemLucro,
  PosVendaQualitativo,
  PosVendaQuantitativo,
  ReversaoTratativas,
  TempoReclamacaoVisita,
  TicketMedio,
  TipoTratativa,
  VendaProdutoSupervisor,
  VendaSupervisor,
  VisitaTecnicaQualitativo,
  VisitaTecnicaQuantitativo,
} from "./indicators";

// Map of indicator components
const indicatorComponents = {
  balancrepres_ticketmedio: VendedoresTicketMedioPanel,
  clientesinativos: ClientesInativosPanel,
  prospeccao: ProspeccaoPanel,
  balanrepres_clienteativo: BalanceamentoRepres_ClienteAtivo,
  causareclamacoes: CausaReclamacoes,
  clientesativos: ClienteAtivo,
  inadimplencia: Inadimplencia,
  indicetratativaquant: IndiceTratativaQuantitativo,
  indicevendaabaixotabela: IndiceVendaAbaixoTabela,
  inventario: Inventario,
  mapacalor: MapaCalor,
  municipioinativo: MunicipioInativo,
  percentualmargemlucro: PercentualMargemLucro,
  posvendaqualitativo: PosVendaQualitativo,
  posvendaquantitativo: PosVendaQuantitativo,
  reversaotratativas: ReversaoTratativas,
  temporeclamacaovisita: TempoReclamacaoVisita,
  ticketmedio: TicketMedio,
  tipotratativa: TipoTratativa,
  vendaprodutosupervisor: VendaProdutoSupervisor,
  vendasupervisor: VendaSupervisor,
  visitatecnicaqualit: VisitaTecnicaQualitativo,
  visitatecnicaquant: VisitaTecnicaQuantitativo,
};

function Dashboard({ empresaName, userId, userType, onLogoff, userName }) {
  const [indicators, setIndicators] = useState([]);
  const [selectedIndicator, setSelectedIndicator] = useState(null);
  const [selectedScreen, setSelectedScreen] = useState(null);

  const location = useLocation();

  useEffect(() => {
    setSelectedIndicator(null);
    setSelectedScreen(null);
  }, [location.pathname]);

  useEffect(() => {
    if (userId) {
      api
        .getIndicatorsByUser(userId)
        .then((data) => {
          setIndicators(data.indicators);
        })
        .catch((error) => {
          console.error("Erro ao carregar indicadores:", error);
        });
    }
  }, [userId]);

  const renderSelectedIndicator = () => {
    if (!selectedIndicator) return null;

    const SelectedComponent = indicatorComponents[selectedIndicator];
    if (SelectedComponent) {
      return <SelectedComponent />;
    }

    return <p>Indicador não encontrado.</p>;
  };

  return (
    <Container fluid className="h-100 p-0">
        <Row className="gx-0 h-100">
            {/* Sidebar fixo */}
            <Col md={3} className="p-0 h-100">
                <Sidebar
                    indicators={indicators}
                    onSelectIndicator={(indicator) => {
                        setSelectedScreen(null);
                        setSelectedIndicator(indicator);
                    }}
                    onSelectScreen={(screen) => {
                        setSelectedIndicator(null);
                        setSelectedScreen(screen);
                    }}
                    onChangePassword={() => {
                        setSelectedScreen("alterarsenhapanel");
                        setSelectedIndicator(null);
                    }}
                    onLogoff={onLogoff}
                />
            </Col>

            {/* Conteúdo Principal */}
            <Col md={9} className="content-wrapper">
                {!selectedScreen && !selectedIndicator && (
                    <div className="text-center">
                        <Image
                            src={logo}
                            alt="Logotipo da Empresa"
                            fluid
                            style={{ maxWidth: "400px", marginBottom: "20px" }}
                        />
                        <h2 style={{ color: "#001d6b" }}>Bem-vindo(a), {userName}!</h2>
                        <h4>Empresa: {empresaName}</h4>
                    </div>
                )}

                {selectedScreen && (
                    selectedScreen === "cadastrousuariopanel" ? (
                        <CadastroUsuarioPanel />
                    ) : selectedScreen === "permissaousuariopanel" ? (
                        <PermissaoUsuarioPanel />
                    ) : selectedScreen === "alterarsenhapanel" ? (
                        <AlterarSenhaPanel userId={userId} />
                    ) : (
                        <p>Outra tela do sistema selecionada.</p>
                    )
                )}

                {!selectedScreen && renderSelectedIndicator()}
            </Col>
        </Row>

    </Container>
  );
}

export default Dashboard;
