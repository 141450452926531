import React, { useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
} from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Permissions from './config/permissions';
import Login from './components/Login';
import CadastrarUsuario from './components/screens/CadastroUsuarioPanel';
import PermissaoUsuario from './components/screens/PermissaoUsuarioPanel';
import AlterarSenhaPanel from './components/screens/AlterarSenhaPanel';
import ForgotPassword from './components/screens/EsqueciSenhaPanel';
import ResetPassword from './components/screens/ResetSenhaPanel';
import VerifyCode from './components/screens/VerificaCodigoPanel';

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [empresaName, setEmpresaName] = useState('');
    const [userId, setUserId] = useState(null);
    const [userType, setUserType] = useState('');
    const [userName, setUserName] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const validateToken = () => {
            const token = localStorage.getItem('token');
            if (!token) {
                console.log('Token ausente. Executando logoff...');
                handleLogoff(false);
                setLoading(false);
                return;
            }
            try {
                const decoded = jwtDecode(token);
                const currentTime = Date.now() / 1000;
                if (decoded.exp < currentTime) {
                    console.log('Token expirado. Executando logoff...');
                    handleLogoff(false);
                    setLoading(false);
                } else {
                    console.log('Token válido. Atualizando estado...');
                    setIsAuthenticated(true);
                    setEmpresaName(localStorage.getItem('empresaName') || '');
                    setUserId(parseInt(localStorage.getItem('userId'), 10) || null);
                    setUserType(localStorage.getItem('userType') || '');
                    setUserName(localStorage.getItem('userName') || '');
                    setLoading(false);
                }
            } catch (error) {
                console.error('Erro ao decodificar token:', error);
                handleLogoff(false);
                setLoading(false);
            }
        };

        validateToken();
    }, []);

    const handleLogin = (userId, userName, empresaName, roles, empresaId) => {
        setIsAuthenticated(true);
        setEmpresaName(empresaName);
        setUserId(userId);
        setUserType(roles);
        setUserName(userName);

        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('empresaName', empresaName);
        localStorage.setItem('userId', userId);
        localStorage.setItem('userType', roles);
        localStorage.setItem('userName', userName);
        localStorage.setItem('empresaId', empresaId);
    };

    const handleLogoff = (redirect = true) => {
        setIsAuthenticated(false);
        setEmpresaName('');
        setUserId(null);
        setUserName('');

        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('empresaName');
        localStorage.removeItem('userId');
        localStorage.removeItem('userName');
        localStorage.removeItem('userType');
        localStorage.removeItem('empresaId');
        localStorage.removeItem('token');

        if (redirect) window.location.href = '/login';
    };

    const ProtectedRoute = ({ element, allowedRoles }) => {
        const token = localStorage.getItem('token');
        if (!token) {
            console.log('Proteção de rota: token ausente.');
            return <Navigate to="/login" />;
        }
        try {
            const decoded = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            if (decoded.exp < currentTime) {
                console.log('Proteção de rota: token expirado.');
                handleLogoff();
                return <Navigate to="/login" />;
            }
            if (allowedRoles && !allowedRoles.includes(localStorage.getItem('userType'))) {
                console.log('Proteção de rota: acesso negado para o tipo de usuário.');
                return <Navigate to="/" />;
            }
            return element;
        } catch (error) {
            console.error('Erro ao validar token na rota protegida:', error);
            handleLogoff();
            return <Navigate to="/login" />;
        }
    };

    if (loading) {
        return <div style={{ textAlign: 'center', marginTop: '20%' }}>Carregando...</div>;
    }

    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route
                        path="/"
                        element={
                            isAuthenticated ? (
                                <Dashboard
                                    empresaName={empresaName}
                                    userId={userId}
                                    userType={userType}
                                    userName={userName}
                                    onLogoff={handleLogoff}
                                />
                            ) : (
                                <Navigate to="/login" />
                            )
                        }
                    />
                    <Route
                        path="/login"
                        element={isAuthenticated ? <Navigate to="/" /> : <Login onLogin={handleLogin} />}
                    />
                    <Route
                        path="/dashboard"
                        element={
                            <ProtectedRoute
                                element={
                                    <Dashboard
                                        empresaName={empresaName}
                                        userId={userId}
                                        userType={userType}
                                        userName={userName}
                                        onLogoff={handleLogoff}
                                    />
                                }
                            />
                        }
                    />
                    <Route
                        path="/cadastrousuario"
                        element={
                            <ProtectedRoute
                                element={<CadastrarUsuario />}
                                allowedRoles={Permissions.AG.concat(Permissions.AS)}
                            />
                        }
                    />
                    <Route
                        path="/permissaousuario"
                        element={
                            <ProtectedRoute
                                element={<PermissaoUsuario />}
                                allowedRoles={Permissions.AG.concat(Permissions.AS)}
                            />
                        }
                    />
                    <Route
                        path="/alterarsenha"
                        element={<ProtectedRoute element={<AlterarSenhaPanel />} />}
                    />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/verify-code" element={<VerifyCode />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
