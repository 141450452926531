import React, { useState } from "react";
import { FaCogs, FaSignOutAlt, FaKey, FaChartBar, FaUsers } from "react-icons/fa";
import { Collapse } from "react-bootstrap";
import "./Sidebar.css";

const RolePermissions = {
  "Admin Geral": ["Permissoes", "CadastroUsuario"],
  "Admin Empresa": ["CadastroUsuario"],
  Supervisor: [],
};

function Sidebar({ indicators = [], onSelectIndicator, onSelectScreen, onLogoff, onChangePassword }) {
  const [openCadastros, setOpenCadastros] = useState(false);
  const [openIndicadores, setOpenIndicadores] = useState(false);
  const [openConfiguracoes, setOpenConfiguracoes] = useState(false);

  // Recuperar roles do localStorage
  const roles = JSON.parse(localStorage.getItem("roles")) || [];
  const userPermissions = roles.reduce((acc, role) => {
    const permissions = RolePermissions[role] || [];
    return [...acc, ...permissions];
  }, []);

  return (
    <div className="sidebar">
      {/* Header do Sidebar */}
      <div className="sidebar-header">
        <FaChartBar size={24} className="sidebar-icon" />
        <span className="sidebar-title">Dashboard</span>
      </div>

      {/* Menu */}
      <ul className="sidebar-menu">
        {/* Cadastros */}
        {userPermissions.length > 0 && (
          <>
            <li
              className={`sidebar-dropdown ${openCadastros ? "active" : ""}`}
              onClick={() => setOpenCadastros(!openCadastros)}
            >
              <FaUsers className="sidebar-icon" />
              <span>Cadastros</span>
            </li>
            <Collapse in={openCadastros}>
              <ul className="sidebar-submenu">
                {userPermissions.includes("Permissoes") && (
                  <li onClick={() => onSelectScreen("permissaousuariopanel")}>
                    <FaKey className="sidebar-icon" />
                    <span>Permissões</span>
                  </li>
                )}
                {userPermissions.includes("CadastroUsuario") && (
                  <li onClick={() => onSelectScreen("cadastrousuariopanel")}>
                    <FaUsers className="sidebar-icon" />
                    <span>Usuários</span>
                  </li>
                )}
              </ul>
            </Collapse>
          </>
        )}

        {/* Indicadores */}
        <li
          className={`sidebar-dropdown ${openIndicadores ? "active" : ""}`}
          onClick={() => setOpenIndicadores(!openIndicadores)}
        >
          <FaChartBar className="sidebar-icon" />
          <span>Indicadores</span>
        </li>
        <Collapse in={openIndicadores}>
          <ul className="sidebar-submenu">
            {indicators.length > 0 ? (
              indicators.map((indicator) => (
                <li key={indicator.id} onClick={() => onSelectIndicator(indicator.name)}>
                  <FaChartBar className="sidebar-icon" />
                  <span>{indicator.descricao}</span>
                </li>
              ))
            ) : (
              <li>
                <FaChartBar className="sidebar-icon" />
                <span>Nenhum indicador disponível</span>
              </li>
            )}
          </ul>
        </Collapse>

        {/* Configurações */}
        <li
          className={`sidebar-dropdown ${openConfiguracoes ? "active" : ""}`}
          onClick={() => setOpenConfiguracoes(!openConfiguracoes)}
        >
          <FaCogs className="sidebar-icon" />
          <span>Configurações</span>
        </li>
        <Collapse in={openConfiguracoes}>
          <ul className="sidebar-submenu">
            <li onClick={onChangePassword}>
              <FaKey className="sidebar-icon" />
              <span>Alterar Senha</span>
            </li>
            <li onClick={onLogoff}>
              <FaSignOutAlt className="sidebar-icon" />
              <span>Logoff</span>
            </li>
          </ul>
        </Collapse>
      </ul>
    </div>
  );
}

export default Sidebar;
