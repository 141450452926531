import React, { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col, Card } from 'react-bootstrap';
import api from '../../services/api';

function VendedoresTicketMedioPanel() {
    const [safra, setSafra] = useState('');
    const [safras, setSafras] = useState([]);
    const [vendedorData, setVendedorData] = useState({ graph_url: null, total_vendedores: 0, ticket_medio: 0 });

    // Carregar safras ao montar o componente
    useEffect(() => {
        api.getSafras().then(data => {
            const sortedSafras = data.safras.sort((a, b) => a.localeCompare(b)); // Ordena as safras alfabeticamente
            setSafras(sortedSafras);
        });
    }, []);

    // Usar useCallback para memorização da função
    const fetchVendedorData = useCallback(async () => {
        if (safra) {
            const filters = { safra };
            const data = await api.getVendedoresTicketMedio(filters); // Chamada à API para pegar dados de vendedores e ticket médio
            setVendedorData(data);
        }
    }, [safra]);

    // Executar a função sempre que safra for alterada
    useEffect(() => {
        fetchVendedorData();
    }, [fetchVendedorData]);

    return (
        <Card className="shadow-sm mb-4">
            <Card.Header as="h5">Vendedores e Ticket Médio por Supervisor</Card.Header>
            <Card.Body>
                <Row className="mb-3">
                    <Col md={12}>
                        <Form.Group controlId="formSafra">
                            <Form.Label>Safra</Form.Label>
                            <Form.Control
                                as="select"
                                value={safra}
                                onChange={e => setSafra(e.target.value)}
                            >
                                <option value="">Selecione a Safra</option>
                                {safras.map(s => (
                                    <option key={s} value={s}>{s}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                {vendedorData.graph_url ? (
                    <div className="vendedores-chart mt-4 text-center"> {/* Aqui aplicamos a classe text-center */}
                        <img src={`data:image/png;base64,${vendedorData.graph_url}`} alt="Quantidade de Vendedores e Ticket Médio por Supervisor" className="img-fluid mx-auto d-block" />
                    </div>
                ) : (
                    <p>Selecione uma safra para ver o gráfico.</p>
                )}
            </Card.Body>

        </Card>
    );
}

export default VendedoresTicketMedioPanel;
