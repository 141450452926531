import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Alert } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

function ResetSenhaPanel() {
    const location = useLocation();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const emailFromQuery = queryParams.get('email');

        if (emailFromQuery) {
            console.log('E-mail recebido da URL:', emailFromQuery); // Log para debug
            setEmail(emailFromQuery);
        } else {
            console.error('E-mail não encontrado na URL');
            setError('E-mail inválido ou não encontrado.');
        }
    }, [location.search]);

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');
    
        if (!email) {
            setError('E-mail inválido ou não encontrado.');
            return;
        }
    
        console.log('Dados enviados para API:', { email, new_password: newPassword }); // Adicionado para depuração
    
        try {
            const response = await axios.post(`${API_BASE_URL}/api/reset-password`, {                             
                email, // Inclui o e-mail no corpo da requisição
                new_password: newPassword,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            console.log('Resposta da API:', response.data); // Adicionado para depuração
            setMessage(response.data.message);
            setTimeout(() => navigate('/login'), 3000); // Redireciona após 3 segundos
        } catch (err) {
            console.error('Erro recebido da API:', err.response?.data || err.message); // Adicionado para depuração
            setError(err.response?.data?.message || 'Erro ao redefinir senha.');
        }
    };
    

    return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div style={{ width: '400px' }}>
                <h3 className="text-center">Alterar Senha</h3>
                {message && <Alert variant="success">{message}</Alert>}
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleResetPassword}>
                    <Form.Group controlId="formNewPassword" className="mb-3">
                        <Form.Label>Nova Senha</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Digite a nova senha"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit" className="w-100">
                        Alterar Senha
                    </Button>
                </Form>
            </div>
        </div>
    );
}

export default ResetSenhaPanel;
