import React, { useState } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import api from '../../services/api';

function AlterarSenhaPanel() {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState({ text: '', type: '' });
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage({ text: '', type: '' });

        if (!oldPassword || !newPassword) {
            setMessage({ text: 'Todos os campos são obrigatórios.', type: 'danger' });
            return;
        }

        try {
            setLoading(true);
            const response = await api.post('/change-password', {
                old_password: oldPassword,
                new_password: newPassword,
            });
            setMessage({ text: response.data.message, type: 'success' });
            setOldPassword('');
            setNewPassword('');
        } catch (error) {
            const errorMsg = error.response?.data?.message || 'Erro ao alterar senha.';
            setMessage({ text: errorMsg, type: 'danger' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Card className="shadow-sm mb-4">
            <Card.Header as="h5">
                Alterar Senha
            </Card.Header>
            <Card.Body>
                {message.text && <Alert variant={message.type}>{message.text}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formOldPassword" className="mb-3">
                        <Form.Label>Senha Atual</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Digite sua senha atual"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="formNewPassword" className="mb-3">
                        <Form.Label>Nova Senha</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Digite sua nova senha"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Button variant="primary" type="submit" className="w-100" disabled={loading}>
                        {loading ? 'Carregando...' : 'Alterar Senha'}
                    </Button>
                </Form>
            </Card.Body>
        </Card>
    );
}

export default AlterarSenhaPanel;
